<template>
  <b-card header-tag="header" footer-tag="footer">
    <template v-slot:header>
      <h6 class="mb-0" v-if="showName == true">
        Device Props ({{ getSafe(() => device.object) }})
      </h6>
      <h6 class="mb-0" v-else>Device Props</h6>
    </template>

    <template v-if="lastPropsLoaded == null">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner variant="primary"> </b-spinner>
      </div>
    </template>

    <b-list-group v-else>
      <template v-for="(item, i) in device_props">
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
          v-bind:key="i"
        >
          {{ item.command }}
          <b-badge variant="primary" pill
            >{{ item.value }} {{ item.unit }}</b-badge
          >
        </b-list-group-item>
      </template>
      <b-alert show variant="secondary" v-if="device_props.length == 0"
        >There are no props for this device</b-alert
      >
    </b-list-group>
    <br>
      <b-badge v-if="lastTimeMessage != ''">{{ lastTimeMessage }}</b-badge>
  </b-card>
</template>

<script>
import { FETCH_DEVICE_PROPS } from "@/core/services/store/devices.module";
export default {
  name: "suitch-status-widget",
  components: {},
  props: {
    device: null,
    showName: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    setInterval(() => {
      if (this.lastTimeLoaded == -1) {
        // this.lastTimeMessage = "loading ...";
      } else {
        const this_time1 = new Date();
        const elapsed_seconds = Math.ceil(
          (this_time1 - this.lastTimeLoaded) / 1000
        );
        if (elapsed_seconds < 60) {
          this.lastTimeMessage = String(elapsed_seconds) + " seconds ago";
        } else {
          this.lastTimeMessage =
            String(Math.floor(elapsed_seconds / 60)) + " minutes ago";
        }
      }
    }, 1000);
    this.downloadData();
    this.unsubscribe=this.$store.subscribe((mutation, state) => {
      if (mutation.type == "setMessage") {
        // verify if the type of message is for this widget
        if (
          this.device != null &&
          state.realtime.last_message.type == "props" &&
          state.realtime.last_message.token == this.device.token
        ) {
          // call again the action to get the status
          this.downloadData();
          const this_time = new Date();
          this.lastTimeLoaded = this_time.getTime();
        }
      }
    });
  },
  beforeDestroy() {
    // Unsubscribe from the store when the component is destroyed
    if (this.unsubscribe) {
      this.unsubscribe();console.log("kill_subscribe");
    }
  },
  data() {
    return {
      unsubscribe: null,
      device_props: [],
      lastPropsLoaded: null,
      lastTimeLoaded: -1,
      lastTimeMessage: "",
    };
  },
  methods: {
    getSafe,
    downloadData() {
      if (this.device != null) {
        this.$store
          .dispatch(FETCH_DEVICE_PROPS, { device_token: this.device.token })
          // go to which page after successfully login
          .then((data) => {
            // download devices list
            if (data == undefined || data == null) {
              // when null
            } else {
              this.device_props = data;
              this.lastPropsLoaded = data;
            }
          });
      }
    },
  },
  watch: {
    device: function (oldval, newval) {
      oldval, newval;
      this.downloadData();
    },
  },
};
function getSafe(fn) {
    try { return fn(); }
    catch (e) {
      // happy
    }
}
</script>
